import { Injectable } from '@angular/core';
import { SortOption } from '@shared/models/types/sort-option';
import { SortOrder } from '@shared/models/types/types.generated';
import { head, isEqual, tail } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';

@Injectable()
export abstract class SortService<T extends string> {
  private _sortOptions = new BehaviorSubject(this.getDefaultSortOptions());
  public readonly sortOptions$ = this._sortOptions
    .asObservable()
    .pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)));
  public readonly primarySortOption$ = this.sortOptions$.pipe(map(([firstSortOption]) => firstSortOption));

  public get sortOptions(): SortOption<T>[] {
    return this._sortOptions.getValue();
  }

  public get primarySortOption(): SortOption<T> {
    return head(this._sortOptions.getValue());
  }

  public get secondarySortOptions(): SortOption<T>[] {
    return tail(this._sortOptions.getValue()) || [];
  }

  public setPrimarySortOption(sortOption: Partial<SortOption<T>>): void {
    const safeSortOption: SortOption<T> = {
      ...this.getDefaultSortOptions()[0],
      ...sortOption,
    };

    this.setSortField(safeSortOption.field);
    this.setSortOrder(safeSortOption.order);
  }

  public setSortField(field: T, setDefaultSortOption = false): void {
    if (!this.isSortField(field)) {
      console.error(`Invalid sort field: ${field}`);
      return;
    }

    const currentSortOption = this.primarySortOption;
    const newSortOptions: SortOption<T>[] = [
      {
        ...currentSortOption,
        field,
      },
    ];

    if (setDefaultSortOption) {
      newSortOptions[0].order = this.getDefaultSortOrderByField(field);
    }

    const secondarySortOption = this.getDefaultSecondaryOptionByField(field);

    if (secondarySortOption) {
      newSortOptions.push(secondarySortOption);
    }

    this._sortOptions.next(newSortOptions);
  }

  public setSortOrder(order: SortOrder) {
    if (!Object.values(SortOrder).includes(order)) {
      console.error(`Invalid sort order: ${order}`);
      return;
    }

    this._sortOptions.next([
      {
        ...this.primarySortOption,
        order,
      },
      ...this.secondarySortOptions,
    ]);
  }

  public isSortKey(key: string): boolean {
    return key === 'field' || key === 'order';
  }

  public abstract getDefaultSortOptions(): SortOption<T>[];
  public abstract getDefaultSortOrderByField(field: T): SortOrder;
  public abstract getDefaultSecondaryOptionByField(field: T): SortOption<T>;
  public abstract isSortField(field: string): boolean;
}
