import type * as Types from '../../models/types/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AccountQueryResult = {
  account?: {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    login: string;
    email?: string | null;
    roles?: Array<string | null> | null;
    relatedEmployee?: {
      salesProfileGoogleDriveId?: string | null;
      salesProfileLastModified?: any | null;
      mostRelevantEmploymentPeriod?: {
        billable?: boolean | null;
        team?: {
          id: string;
          name: string;
          shortName?: string | null;
          parent?: { id: string; name: string; shortName?: string | null } | null;
        } | null;
        employmentStatus?: { id: string; name: string; detail: string } | null;
      } | null;
    } | null;
  } | null;
};

export const AccountDocument = gql`
  query Account {
    account {
      id
      firstName
      lastName
      login
      email
      roles
      relatedEmployee {
        mostRelevantEmploymentPeriod {
          team {
            id
            name
            shortName
            parent {
              id
              name
              shortName
            }
          }
          employmentStatus {
            id
            name
            detail
          }
          billable
        }
        salesProfileGoogleDriveId
        salesProfileLastModified
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AccountQueryService extends Apollo.Query<AccountQueryResult, AccountQueryVariables> {
  document = AccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
