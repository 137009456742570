import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-buttons',
  templateUrl: './dialog-buttons.component.html',
  styleUrls: ['./dialog-buttons.component.scss'],
})
export class DialogButtonsComponent {
  @Input() submitText: string;
  @Input() cancelText: string;
  @Input() submitType: 'submit' | 'button' = 'submit';

  @Input() disableSubmit = false;
  @Input() isLoading = false;

  @Output() cancelEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter();

  cancelDialog() {
    this.cancelEvent.emit();
  }

  submitDialog() {
    this.submitEvent.emit();
  }
}
