<app-dialog
  [titleText]="'common:dialogs.new_version_available.title' | i18next"
  [messageText]="'common:dialogs.new_version_available.message' | i18next"
  [showDialog]="showDialog$ | async"
  (cancelEvent)="cancel()"
>
  <div class="form">
    <app-dialog-buttons
      [submitText]="'common:dialogs.new_version_available.submit' | i18next"
      [cancelText]="'common:dialogs.new_version_available.cancel' | i18next"
      (cancelEvent)="cancel()"
      (submitEvent)="submit()"
      submitType="button"
    ></app-dialog-buttons>
  </div>
</app-dialog>
