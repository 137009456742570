import { Pipe, PipeTransform } from '@angular/core';

type Employee = {
  firstName?: string;
  lastName?: string;
};

@Pipe({
  name: 'employeeName',
})
export class EmployeeNamePipe implements PipeTransform {
  transform(value: Employee, fallback?: string): string {
    if (!value) return fallback;

    const { firstName, lastName } = value;

    return firstName && lastName ? `${firstName} ${lastName}` : fallback;
  }
}
