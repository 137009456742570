import { Injectable } from '@angular/core';
import { CacheKey, LocalStorageService } from '@shared/services/local-storage.service';
import packageJson from 'package.json';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private readonly CACHED_APP_VERSION = this.localStorageService.loadKey(CacheKey.APP_VERSION);

  static readonly CURRENT_APP_VERSION = packageJson.version;

  constructor(private localStorageService: LocalStorageService) {}

  public migrateLocalStorage(): void {
    if (this.CACHED_APP_VERSION === VersionService.CURRENT_APP_VERSION) return; // no migration necessary

    switch (this.CACHED_APP_VERSION) {
      // add migrations here, do not use breaks to ensure incremental migrations of all versions
      case null:
        this.migrateToVersion000();
    }

    this.localStorageService.saveKey(CacheKey.APP_VERSION, VersionService.CURRENT_APP_VERSION);
  }

  private migrateToVersion000() {
    this.localStorageService.saveKey(CacheKey.NAVBAR_IS_COLLAPSED, true);
  }
}
