import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { CriteriaOperator } from '@shared/models/types/types.generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { I18NextPipe } from 'angular-i18next';
import { SelectionOption } from '@shared/models/types/selection-option';
import { isValid } from 'date-fns';

export interface FilterCriteriaFormModel<T> {
  key?: FormControl<T>;
  operator?: FormControl<CriteriaOperator>;
  value?: FormControl<string>;
}

@Component({
  selector: 'app-filter-criteria',
  templateUrl: './filter-criteria.component.html',
  styleUrls: ['./filter-criteria.component.scss'],
})
export class FilterCriteriaComponent<T> implements OnInit, OnChanges {
  @Input() enableDynamicValues: boolean = false;
  @Input()
  formGroup: FormGroup<FilterCriteriaFormModel<T>>;
  @Input()
  filterKeyOptions: T[];
  @Input()
  valueTemplate: TemplateRef<any>;
  @Input() dynamicTemplate?: TemplateRef<any>;
  @Input()
  allowedOperators?: CriteriaOperator[] = Object.values(CriteriaOperator);
  @Input()
  displayValue?: string;

  collapsed: boolean = false;
  showDynamicTemplate: boolean = false;

  translatedFilterKeyOptions: SelectionOption<T>[];

  constructor(private i18Next: I18NextPipe) {}

  ngOnInit(): void {
    this.formGroup.controls.key.valueChanges.subscribe(() => {
      this.formGroup.patchValue({
        operator: CriteriaOperator.Equals,
        value: null,
      });
    });

    this.translatedFilterKeyOptions = this.filterKeyOptions
      .map((option: T) => ({
        label: this.i18Next.transform(('common:filterKeys.' + option) as string),
        value: option,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displayValue || (changes.formGroup.currentValue as FormGroup<FilterCriteriaFormModel<T>>).value.value)
      if (!!this.formGroup.value.value && !isValid(new Date(this.displayValue))) {
        this.showDynamicTemplate = true;
      }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  toggleDynamicInput() {
    this.showDynamicTemplate = !this.showDynamicTemplate;

    if (!this.showDynamicTemplate && !isValid(new Date(this.displayValue))) {
      this.formGroup.patchValue({
        value: null,
      });
    }
  }

  get label() {
    const key = this.i18Next.transform(('common:filterKeys.' + this.formGroup.value.key) as string);
    const operator = this.i18Next.transform('common:filterOperators.' + this.formGroup.value.operator);
    const value = this.displayValue ? this.displayValue : this.formGroup.value.value;
    return key && operator && value ? `${key} ${operator} ${value}` : key;
  }

  static emptyFilterCriteriaGroup<T>() {
    return new FormGroup<FilterCriteriaFormModel<T>>({
      key: new FormControl<T>(undefined, Validators.required),
      operator: new FormControl<CriteriaOperator>(CriteriaOperator.Equals, Validators.required),
      value: new FormControl<string>(null, Validators.required),
    });
  }
}
