<div class="dialog__footer">
  <ino-button
    id="cancelButton"
    role="button"
    type="button"
    [attr.aria-label]="cancelText"
    variant="outlined"
    (click)="cancelDialog()"
    data-cy="cancel-button-dialog"
  >
    {{ cancelText }}
  </ino-button>
  <ino-button
    id="submitButton"
    [type]="submitType"
    role="button"
    variant="filled"
    [attr.aria-label]="submitText"
    (click)="submitType === 'button' && submitDialog()"
    [disabled]="disableSubmit"
    [loading]="isLoading"
    data-cy="submit-button-dialog"
  >
    {{ submitText }}
  </ino-button>
</div>
