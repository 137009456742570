import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CacheKey, LocalStorageService } from '@shared/services/local-storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { RoutePaths } from '../../../app-routing.module';
import { APP_CONFIG } from 'src/app/configuration/configuration.module';
import { AppConfig } from '@shared/models/interfaces/app-config';
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs';
import { RouteCanMatch } from '@shared/services/route-can-match.service';

@Component({
  selector: 'app-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styleUrls: ['./nav-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavDrawerComponent {
  public isCollapsed: boolean = true;

  public routes = RoutePaths;

  public readonly user$ = this.userService.user$;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    public routeIsEnabled: RouteCanMatch,
    private userService: UserService,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    this.isCollapsed = localStorageService.loadKey(CacheKey.NAVBAR_IS_COLLAPSED, true);

    router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      const mainHeader: HTMLElement = document.querySelector('#main-content');
      if (mainHeader) {
        mainHeader.focus();
      }
    });
  }

  public onLogoClick = (): void => void this.router.navigate([RoutePaths.HOME]);

  public toggleCollapsed = (event: CustomEvent<boolean>): void => {
    this.isCollapsed = !event.detail;
    this.localStorageService.saveKey(CacheKey.NAVBAR_IS_COLLAPSED, this.isCollapsed);
  };
}
