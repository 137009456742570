import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@shared/models/interfaces/app-config';
import * as Sentry from '@sentry/angular-ivy';
import { APP_CONFIG } from './configuration.module';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

const initSentry = (config: AppConfig): void => {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [Sentry.browserTracingIntegration({})],
    beforeBreadcrumb: excludeGraphQLFetch,
    environment: config.environment,
    release: config.release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'app-uat.inca.inma.inovex.io', 'new.inca.inovex.de', 'inca-uat.inovex.de'],
  });
};

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class SentryModule {
  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    initSentry(appConfig);
  }
}
