import { Team as TeamDto } from 'src/app/shared/models/types/types.generated';

type Input = Pick<TeamDto, 'id' | 'name' | 'shortName'>;

class Team {
  readonly id: string;
  readonly name: string;
  readonly shortName: string;

  constructor(
    dto: Input,
    public readonly isMainTeam = false,
  ) {
    this.id = dto.id;
    this.name = dto.name;
    this.shortName = Team.trimShortName(dto.shortName);
  }

  static trimShortName = (name: string) => {
    if (name === 'inovex') {
      return name;
    }

    return name?.replace(/LoB-|inovex/i, '').trim();
  };

  static compare = (teamA: Team, teamB: Team): number => {
    if (teamA.isMainTeam && !teamB.isMainTeam) return -1;

    if (teamB.isMainTeam && !teamA.isMainTeam) return 1;

    return teamA.shortName.toLowerCase().localeCompare(teamB.shortName.toLowerCase());
  };
}

export default Team;
