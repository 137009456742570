<ino-nav-drawer class="nav-drawer" [open]="!isCollapsed" (openChange)="toggleCollapsed($any($any($event)))">
  <ino-list slot="header">
    <ino-img
      (click)="onLogoClick()"
      class="nav-drawer__logo"
      role="img"
      alt="inovex Inca Logo"
      src="assets/images/INCA_Logo_NF.svg"
    >
    </ino-img>
  </ino-list>
  <ino-list slot="content" role="navigation" aria-label="main navigation">
    <a [routerLink]="['/', routes.DASHBOARD]">
      @if (routeIsEnabled.isRouteMatch(routes.DASHBOARD) | async) {
        <ino-nav-item
          id="nav-drawer__main--dashboard"
          class="nav-drawer__main--dashboard"
          role="link"
          [text]="'dashboard:title' | i18next"
          [activated]="dashboardLink.isActive"
          routerLinkActive
          #dashboardLink="routerLinkActive"
        >
          @if (dashboardLink.isActive) {
            <ino-icon [src]="'../../../../assets/icons/dashboard-active.svg'"></ino-icon>
          } @else {
            <ino-icon [src]="'../../../../assets/icons/dashboard-inactive.svg'"></ino-icon>
          }
        </ino-nav-item>
      }
    </a>

    <a [routerLink]="['/', routes.OPPORTUNITIES]">
      @if (routeIsEnabled.isRouteMatch(routes.OPPORTUNITIES) | async) {
        <ino-nav-item
          id="nav-drawer__main--opportunities"
          role="menuitem"
          tabindex="0"
          [text]="'opportunities:title' | i18next"
          [activated]="opportunitiesLink.isActive"
          routerLinkActive
          ariaCurrentWhenActive="page"
          #opportunitiesLink="routerLinkActive"
        >
          <ino-icon icon="opportunity"></ino-icon>
        </ino-nav-item>
      }
    </a>
    <a [routerLink]="['/', routes.PROJECTS]">
      @if (routeIsEnabled.isRouteMatch(routes.PROJECTS) | async) {
        <ino-nav-item
          id="nav-drawer__main--projects"
          role="menuitem"
          tabindex="-1"
          [text]="'projects:title' | i18next"
          [activated]="projectsLink.isActive"
          routerLinkActive
          ariaCurrentWhenActive="page"
          #projectsLink="routerLinkActive"
        >
          <ino-icon icon="partner"></ino-icon>
        </ino-nav-item>
      }
    </a>
    <a [routerLink]="['/', routes.EMPLOYEES]">
      @if (routeIsEnabled.isRouteMatch(routes.EMPLOYEES) | async) {
        <ino-nav-item
          id="nav-drawer__main--employees"
          role="menuitem"
          tabindex="-1"
          [text]="'employees:title' | i18next"
          [activated]="employeesLink.isActive"
          routerLinkActive
          ariaCurrentWhenActive="page"
          #employeesLink="routerLinkActive"
        >
          <ino-icon icon="employee"></ino-icon>
        </ino-nav-item>
      }
    </a>
    <a [routerLink]="['/', routes.CHESS]">
      @if (routeIsEnabled.isRouteMatch(routes.CHESS) | async) {
        <ino-nav-item
          id="nav-drawer__main--chess"
          role="menuitem"
          tabindex="-1"
          [text]="'chess:title' | i18next"
          [activated]="chessLink.isActive"
          routerLinkActive
          ariaCurrentWhenActive="page"
          #chessLink="routerLinkActive"
        >
          <ino-icon class="link-form__icon" width="24" height="24" src="assets/icons/chess.svg"></ino-icon>
        </ino-nav-item>
      }
    </a>
    <a [routerLink]="['/', routes.ADMINISTRATION]">
      @if (routeIsEnabled.isRouteMatch(routes.ADMINISTRATION) | async) {
        <ino-nav-item
          id="nav-drawer__main--administration"
          role="menuitem"
          tabindex="-1"
          [text]="'administration:title' | i18next"
          [activated]="administrationLink.isActive"
          routerLinkActive
          ariaCurrentWhenActive="page"
          #administrationLink="routerLinkActive"
        >
          <ino-icon class="link-form__icon" width="24" height="24" icon="settings"></ino-icon>
        </ino-nav-item>
      }
    </a>
  </ino-list>
  <ino-list slot="footer" role="menubar" aria-orientation="vertical">
    @if (user$ | async; as user) {
      <a [routerLink]="['/', routes.EMPLOYEES, 'details', user?.id]">
        <ino-nav-item id="nav-drawer__me" role="menuitem" tabindex="0" [text]="user | employeeName">
          <app-avatar [employee]="user" [isLoading]="!user" [disableProfileLink]="true"></app-avatar>
        </ino-nav-item>
      </a>
    }
    <a [routerLink]="['/', routes.ABOUT]">
      @if (routeIsEnabled.isRouteMatch(routes.ABOUT) | async) {
        <ino-nav-item
          id="nav-drawer__footer--about"
          role="menuitem"
          tabindex="-1"
          [text]="'about:title' | i18next"
          [activated]="aboutLink.isActive"
          routerLinkActive
          ariaCurrentWhenActive="page"
          #aboutLink="routerLinkActive"
        >
          <ino-icon icon="info"></ino-icon>
        </ino-nav-item>
      }
    </a>
  </ino-list>

  <!-- tooltips for nav bar -->
  @if (isCollapsed) {
    @if (routeIsEnabled.isRouteMatch(routes.DASHBOARD) | async) {
      <ino-tooltip
        for="nav-drawer__main--dashboard"
        [label]="'dashboard:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    @if (routeIsEnabled.isRouteMatch(routes.OPPORTUNITIES) | async) {
      <ino-tooltip
        for="nav-drawer__main--opportunities"
        [label]="'opportunities:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    @if (routeIsEnabled.isRouteMatch(routes.CHESS) | async) {
      <ino-tooltip
        for="nav-drawer__main--chess"
        [label]="'chess:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    @if (routeIsEnabled.isRouteMatch(routes.PROJECTS) | async) {
      <ino-tooltip
        for="nav-drawer__main--projects"
        [label]="'projects:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    @if (routeIsEnabled.isRouteMatch(routes.EMPLOYEES) | async) {
      <ino-tooltip
        for="nav-drawer__main--employees"
        [label]="'employees:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    @if (routeIsEnabled.isRouteMatch(routes.ADMINISTRATION) | async) {
      <ino-tooltip
        for="nav-drawer__main--administration"
        [label]="'administration:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    @if (routeIsEnabled.isRouteMatch(routes.ABOUT) | async) {
      <ino-tooltip
        for="nav-drawer__footer--about"
        [label]="'about:title' | i18next"
        placement="right"
        colorScheme="light"
        delay="300"
      ></ino-tooltip>
    }
    <ino-tooltip
      for="nav-drawer__me"
      [label]="'common:navigation.me' | i18next"
      placement="right"
      colorScheme="light"
      delay="300"
    ></ino-tooltip>
  }
  <main id="main-content" class="nav-drawer__main" slot="app">
    <router-outlet></router-outlet>
  </main>
</ino-nav-drawer>
