import { Pipe, PipeTransform } from '@angular/core';
import { IdentifiableEntity } from '@shared/models/interfaces/identifiable-entity';

@Pipe({
  name: 'entityMatchIdentifier',
})
export class EntityMatchIdentifierPipe implements PipeTransform {
  transform(identifier: string, entity: IdentifiableEntity): boolean {
    return entity.matchesIdentity(identifier);
  }
}
