<!--If key ist undefined (when adding a new filter) show simple select-->
<div class="filter-criteria">
  @if (!formGroup.value.key) {
    <ino-select
      [formControl]="formGroup.controls.key"
      [outline]="true"
      [label]="'common:filterCriteria.filterKey' | i18next"
      class="filter-criteria__initial-select"
      data-cy="filter-criteria__initial-select"
    >
      @for (option of translatedFilterKeyOptions; track option.label) {
        <ino-option [value]="option.value">
          {{ option.label }}
        </ino-option>
      }
    </ino-select>
  }

  <!--If key is already defined, show filter box-->
  @if (formGroup?.value?.key) {
    <app-filter-box
      [title]="label"
      [loading]="false"
      [collapsed]="collapsed"
      (collapsedChange)="toggleCollapsed()"
      [useCustomHeader]="!collapsed"
    >
      <div header class="filter-criteria__header">
        <ino-select [formControl]="formGroup.controls.key" class="small no-line">
          @for (option of translatedFilterKeyOptions; track option.label) {
            <ino-option [value]="option.value">
              {{ option.label }}
            </ino-option>
          }
        </ino-select>
        <div class="divider-line"></div>
      </div>

      <section data-cy="filter-criteria__input-group" [formGroup]="formGroup">
        @if (enableDynamicValues) {
          <div class="mb-3 flex items-center gap-2 pl-1 pr-3">
            <ino-checkbox [checked]="showDynamicTemplate" (checkedChange)="toggleDynamicInput()">{{
              'common:filterDate.dynamicTitle' | i18next
            }}</ino-checkbox>
            <div class="">
              <ino-icon id="dynamic-values-description" icon="info"></ino-icon>
              <ino-tooltip for="dynamic-values-description" placement="top" colorScheme="light" delay="300">
                <div class="flex flex-col gap-4">
                  <p class="reset-paragraph text-sm text-black">
                    {{ 'common:filterDate.dynamicDescription' | i18next }}
                  </p>
                  <p class="reset-paragraph text-sm text-black">
                    {{ 'common:filterDate.dynamicDescriptionExample_1' | i18next }}
                  </p>
                  <p class="reset-paragraph text-sm text-black">
                    {{ 'common:filterDate.dynamicDescriptionExample_2' | i18next }}
                  </p>
                  <p class="reset-paragraph text-sm text-black">
                    {{ 'common:filterDate.dynamicDescriptionValues' | i18next }}
                  </p>
                </div>
              </ino-tooltip>
            </div>
          </div>
        }
        <div class="grid grid-cols-[1fr_5fr] gap-2 px-3 pb-2">
          <ino-select formControlName="operator" class="small" data-cy="filter-criteria__operator">
            @for (option of allowedOperators; track option) {
              <ino-option [value]="option">
                <div class="filter-criteria__operator-option">
                  <div>{{ 'common:filterOperators.' + option | i18next }}</div>
                  <ino-icon [id]="option" icon="info"></ino-icon>
                  <ino-tooltip [for]="option" placement="left" delay="300" colorScheme="light">
                    {{ 'common:operatorTooltips.' + option | i18next }}
                  </ino-tooltip>
                </div>
              </ino-option>
            }
          </ino-select>

          <ng-container
            [ngTemplateOutlet]="showDynamicTemplate && enableDynamicValues ? dynamicTemplate : valueTemplate"
            [ngTemplateOutletContext]="{ $implicit: formGroup }"
          >
          </ng-container>
        </div>
      </section>
    </app-filter-box>
  }
</div>
