import { Injectable, inject } from '@angular/core';
import { I18NextPipe, PipeOptions } from 'angular-i18next';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Notification } from '../models/types/notification';
import { InoSnackbar } from '@inovex.de/elements-angular';
import { Event, Router } from '@angular/router';

type AddNotificationOptions = Partial<{
  i18nOptions: PipeOptions;
  actionOptions: {
    i18nKey: string;
    action: () => void;
  };
}>;

type Options = AddNotificationOptions & {
  type: InoSnackbar['type'];
  timeoutFn: (message: string) => number;
  fallbackMsg?: string;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  router = inject(Router);

  public notifications$: Observable<Notification[]>;
  private _notifications: BehaviorSubject<Notification[]>;

  constructor(private i18NextPipe: I18NextPipe) {
    this._notifications = new BehaviorSubject<Notification[]>([]);
    this.notifications$ = this._notifications.asObservable();

    this.router.events.subscribe((event: Event) => {
      if (event.type === 0 && this._notifications.getValue().length > 0) {
        this.removeAllNotifications('error');
      }
    });
  }

  public notifyOnCompletion<T>(
    obs$: Observable<T>,
    i18nKey: string,
    i18nErrorKey?: string,
    i18nOptions?: PipeOptions,
    type: Exclude<Notification['type'], 'error'> = 'success',
  ): Observable<T> {
    let hasError = false;
    const defaultErrorKey = 'common:notification.error';

    return obs$.pipe(
      catchError((err: Error) => {
        hasError = true;
        this.addErrorNotification(i18nErrorKey ?? defaultErrorKey);
        throw err;
      }),
      finalize(() => {
        if (!hasError) {
          this.addFiniteNotification(i18nKey, { i18nOptions, type });
        }
      }),
    );
  }

  public addInfoNotification(i18nKey: string, options?: AddNotificationOptions): number {
    return this.addNotification(i18nKey, {
      ...options,
      type: 'info',
      timeoutFn: () => -1,
    });
  }

  public addWarningNotification(i18nKey: string, options?: AddNotificationOptions): number {
    // return this.addFiniteNotification(i18nKey, { type: 'warning', ...options });
    return this.addNotification(i18nKey, {
      type: 'warning',
      timeoutFn: () => -1,
      ...options,
    });
  }

  public addSuccessNotification(i18nKey: string, options?: AddNotificationOptions): number {
    return this.addFiniteNotification(i18nKey, { type: 'success', ...options });
  }

  public addErrorNotification(i18nKey: string, options?: AddNotificationOptions): number {
    return this.addNotification(i18nKey, {
      fallbackMsg: this.i18NextPipe.transform('errors:generic'),
      type: 'error',
      timeoutFn: () => -1,
      ...options,
    });
  }

  public removeNotification(notificationId: number) {
    this._notifications.next(this._notifications.getValue().filter(({ id }) => id !== notificationId));
  }

  public removeAllNotifications(type: Notification['type']) {
    this._notifications.next(this._notifications.getValue().filter((notification) => notification.type !== type));
  }

  private addFiniteNotification(i18nKey: string, options: Omit<Options, 'timeoutFn'>): number {
    return this.addNotification(i18nKey, {
      timeoutFn: (message: string) => Math.min(Math.max(2000, message.length * 60), 7000),
      ...options,
    });
  }

  private addNotification(
    i18nKey: string,
    { i18nOptions = {}, fallbackMsg, timeoutFn, type, actionOptions }: Options,
  ): number {
    const message = this.i18NextPipe.transform(i18nKey, i18nOptions);

    if (!message && !fallbackMsg) {
      throw new Error(`Missing i18next key: ${i18nKey}`);
    }

    const generatedID = NotificationService.generateUniqueId();

    const notification: Notification = {
      id: generatedID,
      message: message ?? fallbackMsg,
      stayVisibleOnHover: true,
      timeout: timeoutFn(message),
      type,
      actionText: actionOptions?.i18nKey ? this.i18NextPipe.transform(actionOptions.i18nKey) : null,
      onActionClick: actionOptions?.action ?? null,
    };

    const alreadyNotified = this._notifications
      .getValue()
      .find((oldNotification: Notification) => oldNotification.message === notification.message);

    if (!alreadyNotified) {
      this._notifications.next([notification, ...this._notifications.getValue()]);
    }

    return generatedID;
  }

  private static generateUniqueId = (): number => new Date().getMilliseconds() + Math.ceil(Math.random() * 100);
}
