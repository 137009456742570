import { Injectable } from '@angular/core';
import { UnsubscribeComponent } from '@shared/components/base/unsubscribe.component';
import { parseDate } from '@shared/utils/utils';
import { isBefore, isEqual, isWithinInterval } from 'date-fns';
import { BehaviorSubject, combineLatest, first, map } from 'rxjs';
import { PolidaysQueryService } from './generated/polidays.guery.service.generated';
import { NotificationService } from '@shared/services/notification.service';
import { I18NextPipe } from 'angular-i18next';
import { finalize } from 'rxjs/operators';
import { Poliday } from '@shared/models/types/poliday';

@Injectable({
  providedIn: 'root',
})
export class PolidayService extends UnsubscribeComponent {
  private _polidays = new BehaviorSubject<Poliday[]>([]);
  public readonly polidays$ = this._polidays.asObservable();

  private _isLoading = new BehaviorSubject(false);
  public readonly isLoading$ = this._isLoading.asObservable();

  constructor(
    private polidaysQueryService: PolidaysQueryService,
    private notificationService: NotificationService,
    private i18NextPipe: I18NextPipe,
  ) {
    super();
    this.loadAllPolidays();
  }

  public loadAllPolidays(): void {
    this._isLoading.next(true);
    const currentYear = new Date().getFullYear();
    this.polidaysQueryService
      .fetch({
        from: currentYear,
        to: currentYear + 1,
      })
      .pipe(
        first(),
        map(({ data, errors }) => {
          if (errors) {
            this.notificationService.addErrorNotification('errors:errors.fetchData', {
              i18nOptions: {
                entity: this.i18NextPipe.transform('common:entities.polidays'),
              },
            });
            return [];
          }

          return data.polidays;
        }),
        map((polidays) =>
          polidays
            .filter((poliday) => poliday.states.length >= 16)
            .map((poliday) => ({
              ...poliday,
              date: parseDate(poliday.date as string),
              workingDayRatio: poliday.isHalfDay ? 50 : 100,
            })),
        ),
        finalize(() => this._isLoading.next(false)),
      )
      .subscribe((polidays) => {
        this._polidays.next(polidays ?? []);
      });
  }

  public getPolidays$ = (from?: Date, to?: Date): Observable<Poliday[]> =>
    combineLatest([this.polidays$, this.isLoading$]).pipe(
      first(([, loading]) => !loading),
      map(([polidays]) => PolidayService.getPolidaysInInterval(polidays, from, to) ?? []),
    );

  private static getPolidaysInInterval(polidays: Poliday[], from: Date, to: Date) {
    if (!from && !to) return [];

    if (from && to && !isBefore(from, to)) return [];

    return polidays.filter(({ date }) => {
      if (from && to)
        return isWithinInterval(date, {
          start: from,
          end: to,
        });

      if (from) return isEqual(date, from);

      if (to) return isEqual(date, to);
    });
  }
}
