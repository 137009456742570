import { NgModule } from '@angular/core';
import { InoElementsModule } from './inovex-elements.module';
import { InmaSharedModule } from '@inovex-inma/shared-angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  exports: [QuicklinkModule, InoElementsModule, InmaSharedModule, I18NextModule],
})
export class ExternalsModule {}
