import { Inject, Injectable } from '@angular/core';
import { TeamsQueryService } from '@shared/services/generated/teams.query.service.generated';
import { UnsubscribeComponent } from '@shared/components/base/unsubscribe.component';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, shareReplay, takeUntil } from 'rxjs/operators';
import Team from '@shared/models/models/team';
import { APP_CONFIG } from '../../configuration/configuration.module';
import { AppConfig } from '@shared/models/interfaces/app-config';

@Injectable({ providedIn: 'root' })
export class TeamsService extends UnsubscribeComponent {
  private _teams = new BehaviorSubject<Team[]>([]);
  public readonly teams$ = this._teams.asObservable().pipe(
    map((teams) => teams.sort(Team.compare)),
    shareReplay(1),
  );

  private _loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loading.asObservable();

  private MAIN_TEAM_IDS = [
    this.appConfig.employees.teams.academyId,
    this.appConfig.employees.teams.adId,
    this.appConfig.employees.teams.dmaId,
    this.appConfig.employees.teams.itoId,
  ];

  private DASHBOARD_FILTER_TEAM_IDS = [
    this.appConfig.employees.teams.adId,
    this.appConfig.employees.teams.dmaId,
    this.appConfig.employees.teams.itoId,
  ];

  constructor(
    private teamsService: TeamsQueryService,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {
    super();
    this.loadAllTeams();
  }

  public getTeamShortNameById = (id: string): string | null =>
    this._teams.getValue().find((element) => element.id === id)?.shortName ?? null;

  public loadAllTeams = (): void => {
    this._loading.next(true);
    this.teamsService
      .fetch()
      .pipe(
        takeUntil(this.destroy$),
        map(({ data }) => data.teams),
        map((teams) => teams.map((dto) => new Team(dto, this.MAIN_TEAM_IDS.includes(dto.id)))),
        finalize(() => this._loading.next(false)),
      )
      .subscribe((teams) => this._teams.next(teams));
  };

  get dashboardTeams(): Team[] {
    return this._teams
      .getValue()
      .filter((team) => team.isMainTeam && team.id !== this.appConfig.employees.teams.academyId);
  }
}
