<ino-dialog
  #inoDialog
  role="dialog"
  class="dialog"
  [class.dialog--open]="showDialog"
  [class.dialog--closed]="!showDialog"
  [open]="showDialog"
  [attr.aria-hidden]="!showDialog"
  [dismissible]="true"
  (close)="cancelDialog()"
>
  <header class="ino-dialog-header" slot="header">
    <div class="ino-dialog-title">
      <h5>{{ titleText }}</h5>
    </div>
  </header>

  <section class="dialog__content" slot="body">
    @if (messageText) {
      <p class="dialog__message">
        {{ messageText }}
      </p>
    }

    <ng-content></ng-content>
  </section>

  <footer slot="footer"></footer>
</ino-dialog>
