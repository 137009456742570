import { FilterOperator, RatedSkill } from '@shared/models/types/types.generated';
import { compareAsc, isValid, parseISO } from 'date-fns';
import { orderBy } from 'lodash-es';
import { v4 as uuidV4 } from 'uuid';
import { HttpErrorResponse } from '@angular/common/http';
import TimeRange from '@shared/models/types/time-range';
import { AppConfigService } from 'src/app/configuration/services/app-config.service';

export const includesAndIgnoreSpecialCharacters = (value: string, filter: string): boolean =>
  normalizeString(value).includes(normalizeString(filter));

export const firstCharacterToLowerCase = (str: string): string => str.charAt(0).toLowerCase() + str.substring(1);

export const parseDate = (utcDateString: Date | string): Date => {
  if (!utcDateString) return undefined;

  if (isValid(utcDateString)) {
    return utcDateString as Date;
  }

  const date = parseISO(utcDateString as string);
  return isValid(date) ? date : undefined;
};

/* eslint-disable */
export const generateUUID = (): string =>
  // @ts-ignore
  self.crypto?.randomUUID ? self.crypto.randomUUID() : uuidV4();
/* eslint-enable */

export const matchUUID = (str: string) => str.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);

export const clearFormArray = (formArray: FormArray): void => {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
};

export const isNumeric = (value: unknown): boolean =>
  value !== null && value !== undefined && value !== '' && !isNaN(Number(value));

export const getIconByTab = (title: string): string => {
  switch (title) {
    case 'project':
      return 'partner';
    case 'customer':
      return 'company';
    case 'contactPerson':
      return 'assets/icons/contact.svg';
    case 'description':
      return 'assets/icons/file.svg';
    case 'participants':
      return 'employee';
    case 'forecast':
      return 'assets/icons/coins.svg';
    case 'links':
      return 'assets/icons/link.svg';
    default:
      return '';
  }
};

export const sortRatedSkills = (skills: Pick<RatedSkill, 'name' | 'rating'>[]): Pick<RatedSkill, 'name' | 'rating'>[] =>
  orderBy(skills, [(s) => s.rating, (s) => s.name.toLocaleLowerCase()], ['desc', 'asc']);

export const compareByName = <T extends { name: string }>(a: T, b: T): boolean => a.name === b.name;

export const compareById = <T extends { id: string }>(a: T, b: T): boolean => a.id === b.id;

export const is401Error = (error: { networkError: HttpErrorResponse }) => error?.networkError.status === 401;

export const compareTimeRangesAsc = (a: TimeRange, b: TimeRange) => {
  const comparedFrom = compareDatesAsc(a.from, b.from);

  if (comparedFrom !== 0) return comparedFrom;

  return compareDatesAsc(a.to, b.to);
};

export const compareDatesAsc = (a?: Date, b?: Date) => {
  if (!a) return -1;

  if (!b) return 1;

  return compareAsc(a, b);
};

export const signOfFilterOperator = (operator: FilterOperator) => {
  switch (operator) {
    case FilterOperator.And:
      return '&';
    case FilterOperator.Or:
      return '|';
    default:
      throw new Error(`Could not find a sign for filter operator '${operator as string}'`);
  }
};

export const isStudent = (employmentStatusId: string): boolean => {
  const studentIds = AppConfigService.APP_CONFIG?.employees?.employmentStatusGroups?.studentIds;
  if (!studentIds) return null;
  return studentIds.includes(employmentStatusId);
};

export const isWorkingStudent = (employmentStatusId: string): boolean => {
  const workingStudentIds = AppConfigService.APP_CONFIG?.employees?.employmentStatusGroups?.workingStudentIds;
  if (!workingStudentIds) return null;
  return workingStudentIds.includes(employmentStatusId);
};

export const isLeadingEmployee = (employmentStatusId: string): boolean => {
  if (!employmentStatusId) return false;
  const leadingIds = AppConfigService.APP_CONFIG?.employees?.employmentStatusGroups?.leadingIds;
  if (!leadingIds) return false;
  return leadingIds.includes(employmentStatusId);
};

export const normalizeString = (value: string): string =>
  value
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
