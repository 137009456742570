import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../configuration/configuration.module';
import { AppConfig } from '@shared/models/interfaces/app-config';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly authFailureUrl = window.encodeURIComponent(window.location.origin + '/#/authorization-error');

  constructor(@Inject(APP_CONFIG) private readonly appConfig: AppConfig) {}
  public authenticate(): void {
    window.open(
      `${this.appConfig.baseUrl}/_api/authentication?auth-success-url=${window.encodeURIComponent(
        window.location.href,
      )}&auth-failure-url=${this.authFailureUrl}`,
      '_self',
    );
  }
}
