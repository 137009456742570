import { Component, Input } from '@angular/core';
import { LoadingViewService } from './loading-view.service';

@Component({
  selector: 'app-loading-view, [loadingView]',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss'],
})
export class LoadingViewComponent {
  @Input() type: string;
  @Input() width: string;
  @Input() height: string;

  isLoading$ = this.loadingViewService.loading$;

  constructor(public loadingViewService: LoadingViewService) {}
}
