import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { calcFirstTimelineDate, convertDateToColumn } from '@shared/utils/timeline-utils';
import { isBefore, isEqual } from 'date-fns';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { debounceTime, Subscription } from 'rxjs';

/**
 * Should only be used in conjunction with the `app-timeline` component.
 */
@Directive({
  selector: '[appTimelineEntry]',
})
export class TimelineEntryDirective implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() appTimelineEntry: any; // placeholder to allow multiple inputs
  @Input() startDate: Date;
  @Input() endDate: Date | null;

  @HostBinding('attr.role') role: string;
  @HostBinding('style.zIndex') zIndex: CSSStyleDeclaration['zIndex'];
  @HostBinding('style.gridColumn')
  gridColumn: CSSStyleDeclaration['gridColumn'];

  private static readonly NON_INCLUSIVE_OFFSET = 1;

  private subscription: Subscription;

  constructor(
    @Inject(TimelineComponent) private parentTimeline: TimelineComponent,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.role = 'gridcell';
    this.zIndex = String(2);
  }

  ngAfterViewInit() {
    this.subscription = this.parentTimeline.gridChanged$.pipe(debounceTime(300)).subscribe(() => {
      this.setGridCol();
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const didAnyDateChange = Object.keys(changes).some(
      (key) => !isEqual(changes[key].currentValue as Date, changes[key].previousValue as Date),
    );

    if (!didAnyDateChange) return;

    this.setGridCol();
  }

  private setGridCol(): void {
    if (!this.parentTimeline)
      throw new Error(
        'Expected to find an <app-timeline> component as a parent to retrieve the start date of the timeline.',
      );

    const firstTimelineDate = calcFirstTimelineDate(this.parentTimeline.startDate);

    let startCol: number;

    if (isBefore(this.startDate, firstTimelineDate)) {
      startCol = 1;
    } else {
      startCol = convertDateToColumn(firstTimelineDate, this.startDate);
    }

    let endCol: number;

    if (this.endDate == null) {
      endCol = -1;
    } else {
      endCol = convertDateToColumn(firstTimelineDate, this.endDate) + TimelineEntryDirective.NON_INCLUSIVE_OFFSET;
    }

    this.gridColumn = `${startCol} / ${endCol}`;
  }
}
