/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterStrategyService } from '@shared/services/filter-strategy.service';
import { combineLatest, filter, switchMap } from 'rxjs';
import { BaseFilterService } from '@shared/services/base-filter.service';
import { NavigationService } from '@shared/services/navigation.service';
import { SortService } from '@shared/services/sort.service';
import { AdvancedFilterInput } from '@shared/models/types/advanced-filter-input';
import { SortOption } from '@shared/models/types/sort-option';
import { isEqual } from 'lodash-es';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class QueryParamService {
  notificationService = inject(NotificationService);
  filterStrategyService = inject(FilterStrategyService);
  navigationService = inject(NavigationService);
  sortService = inject(SortService<string>);
  router = inject(Router);
  route = inject(ActivatedRoute);

  constructor() {
    /*eslint-disable @typescript-eslint/no-unsafe-return */
    this.route.queryParams
      .pipe(filter((params: Params) => params['advancedFilter']))
      .subscribe((params: Params) => this.setFilterAndFilterStrategy(params));

    combineLatest([
      this.filterStrategyService.activeFilterService$.pipe(
        switchMap((service: BaseFilterService<any>) => service.filters$),
      ),
      this.filterStrategyService.activeFilterService$.pipe(
        switchMap((service: BaseFilterService<any>) => service.searchTerm$),
      ),
      this.sortService.primarySortOption$,
      this.navigationService.inListRoute$,
    ])
      .pipe(
        filter(([, , , inListRoute]) => inListRoute), // query params should only be applied in list view
      )
      .subscribe(([filters, search, sortOptions]) => {
        this.encodeFilter(filters, search, sortOptions);
      });
  }

  setFilterAndFilterStrategy(params: Params) {
    let filterAsJson: AdvancedFilterInput<any>;

    if (this.router.url.includes(this.filterStrategyService.activeListService().filterService.entity?.toString())) {
      filterAsJson = JSON.parse(atob(params['advancedFilter'] as string));

      if (params.sort) {
        const sortOptionsAsJson: SortOption<string> = JSON.parse(atob(params.sort as string));

        this.sortService.setPrimarySortOption(sortOptionsAsJson);
      }

      const currentFilter = this.filterStrategyService.activeFilterService().filters;

      if (!filterAsJson.rootNode) {
        this.filterStrategyService.activeFilterService().resetFilters();
        this.notificationService.addErrorNotification('errors:filters.oldFilter');
      } else {
        if (!isEqual(currentFilter, filterAsJson)) {
          this.filterStrategyService.activeFilterService().setPartialFilters(filterAsJson);
        }
      }

      if (params.search) {
        this.filterStrategyService.activeFilterService().updateSearchTerm(params.search as string);
      }
    }
  }

  encodeFilter(filters, search, sortOptions: SortOption<string>) {
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        advancedFilter: btoa(JSON.stringify(filters)),
        sort: btoa(JSON.stringify(sortOptions)),
        search,
      },
    });
  }
}
