import { Pipe, PipeTransform } from '@angular/core';
import { I18NextService } from 'angular-i18next';
import { formatDistanceToNow } from 'date-fns';
import * as locales from 'date-fns/locale';
import { parseDate } from '@shared/utils/utils';

@Pipe({
  name: 'distanceToNow',
})
export class DistanceToNowPipe implements PipeTransform {
  constructor(private i18nextService: I18NextService) {}

  transform(dateStr: string): string {
    return formatDistanceToNow(parseDate(dateStr), {
      addSuffix: true,
      locale: locales[this.i18nextService.language || 'de'] as Locale,
    });
  }
}
