import { inject, Injectable } from '@angular/core';
import { from, interval, switchMap } from 'rxjs';
import { AppConfigService } from '../../configuration/services/app-config.service';
import { filter } from 'rxjs/operators';
import { APP_CONFIG } from '../../configuration/configuration.module';
import { AppConfig } from '@shared/models/interfaces/app-config';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class NewVersionWatcherService {
  private appConfig: AppConfig = inject(APP_CONFIG);
  notifictionService: NotificationService = inject(NotificationService);

  private static REFRESH_INTERVAL_SECONDS = 60;

  setupWatcher() {
    interval(NewVersionWatcherService.REFRESH_INTERVAL_SECONDS * 1000)
      .pipe(
        switchMap(() => from(AppConfigService.fetchAppVersion())),
        filter((newestConfig: Pick<AppConfig, 'release'>) => newestConfig.release !== this.appConfig.release),
      )
      .subscribe(() =>
        this.notifictionService.addInfoNotification('common:notification.newVersion.message', {
          actionOptions: {
            i18nKey: 'common:notification.newVersion.reload',
            action: () => window.location.reload(),
          },
        }),
      );
  }
}
