import { Injectable } from '@angular/core';
import { CanMatch, Route } from '@angular/router';
import { RoutePaths } from '../../app-routing.module';
import { UserService } from './user.service';
import { UserRole } from '@shared/models/types/user-info';
import { filter, first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteCanMatch implements CanMatch {
  private resolvedPermissionsMap: { [path: string]: Promise<boolean> } = {};

  constructor(private userService: UserService) {}

  canMatch(route: Route): Promise<boolean> {
    return this.isRouteMatch(route.path as RoutePaths);
  }

  public isRouteMatch(path: RoutePaths): Promise<boolean> {
    if (this.resolvedPermissionsMap[path] !== undefined) {
      return this.resolvedPermissionsMap[path];
    } else {
      let resolverFn: (value: boolean | PromiseLike<boolean>) => void;
      this.resolvedPermissionsMap[path] = new Promise((resolve) => {
        resolverFn = resolve;
      });

      switch (path) {
        case RoutePaths.HOME:
        case RoutePaths.OPPORTUNITIES:
        case RoutePaths.ABOUT:
        case RoutePaths.PROJECTS:
        case RoutePaths.EMPLOYEES:
        case RoutePaths.DASHBOARD:
          resolverFn(true);
          break;

        case RoutePaths.ADMINISTRATION:
          this.userService.user$
            .pipe(
              filter((user) => user !== null),
              first(),
            )
            .subscribe(() => {
              resolverFn(this.userService.hasAnyRole([UserRole.ROLE_TEAMLEAD, UserRole.ROLE_ADMIN]));
            });
          break;

        case RoutePaths.CHESS:
          this.userService.user$
            .pipe(
              filter((user) => user !== null),
              first(),
            )
            .subscribe(() => {
              resolverFn(
                this.userService.hasAnyRole([UserRole.ROLE_TEAMLEAD, UserRole.ROLE_BIZDEV, UserRole.ROLE_ADMIN]),
              );
            });
          break;

        default:
          resolverFn(false);
      }

      return this.resolvedPermissionsMap[path];
    }
  }
}
