/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { UnsubscribeComponent } from '@shared/components/base/unsubscribe.component';
import { NotificationService } from '@shared/services/notification.service';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs';
import { SortService } from './sort.service';
import { BaseFilterService } from '@shared/services/base-filter.service';
import { AdvancedFilterInput } from '@shared/models/types/advanced-filter-input';

export type OptionalFilterAndSortQueryVariables = {
  [key: string]: unknown;
  filter?: AdvancedFilterInput<unknown>;
  sort?: unknown;
};

@Injectable()
export abstract class ListService<
  QueryResult extends Record<string, unknown[]>,
  QueryVariables extends OptionalFilterAndSortQueryVariables,
> extends UnsubscribeComponent {
  private _loading = new BehaviorSubject<boolean>(true);
  public _count = new BehaviorSubject<number>(0);
  private _listData = new BehaviorSubject<QueryResult[keyof QueryResult]>([] as QueryResult[keyof QueryResult]);

  public readonly listData$ = this._listData.asObservable();
  public readonly count$ = this._count.asObservable();
  public readonly loading$ = this._loading.asObservable();

  private sortService = inject(SortService<string>);
  private notificationService = inject(NotificationService);

  constructor(public filterService: BaseFilterService<any>) {
    super();

    combineLatest([this.filterService.filters$.pipe(debounceTime(300)), this.sortService.sortOptions$])
      .pipe(
        distinctUntilChanged((prev, next) => isEqual(prev, next)),
        switchMap(([filters, sortOptions]) =>
          this.loadData({
            filter: filters,
            sort: sortOptions,
          } as QueryVariables),
        ),
      )
      .subscribe(this.emitData);
  }

  get listData(): QueryResult[keyof QueryResult] {
    return this._listData.getValue();
  }

  public loadData = (queryVariables: QueryVariables): Observable<ApolloQueryResult<QueryResult>> => {
    this._loading.next(true);
    return this.fetchData(queryVariables);
  };

  public reloadData = () =>
    this.loadData({
      filter: this.filterService.filters,
      sort: this.sortService.sortOptions,
    } as QueryVariables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.emitData);

  protected abstract fetchData(variables: QueryVariables): Observable<ApolloQueryResult<QueryResult>>;

  private emitData = ({ data, errors }: ApolloQueryResult<QueryResult>) => {
    if (errors) {
      this.notificationService.addErrorNotification('errors:errors.list.fetch');
    }
    const list = (data ? data[Object.keys(data)[0]] || [] : []) as QueryResult[keyof QueryResult];

    this._listData.next(list);
    this._loading.next(false);
  };
}
