import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfigService } from './app/configuration/services/app-config.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from './app/configuration/configuration.module';

AppConfigService.loadAppConfig()
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {
        provide: APP_CONFIG,
        useValue: config,
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((e) => console.error(e));
