import type * as Types from '../../models/types/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PolidaysQueryVariables = Types.Exact<{
  from?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  to?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PolidaysQueryResult = {
  polidays?: Array<{
    date?: any | null;
    name?: string | null;
    isHalfDay?: boolean | null;
    states?: Array<string | null> | null;
  } | null> | null;
};

export const PolidaysDocument = gql`
  query Polidays($from: Int, $to: Int) {
    polidays(filter: { years: { min: $from, max: $to } }) {
      date
      name
      isHalfDay
      states
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PolidaysQueryService extends Apollo.Query<PolidaysQueryResult, PolidaysQueryVariables> {
  document = PolidaysDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
