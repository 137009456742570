import { Pipe, PipeTransform } from '@angular/core';
import { RoutePaths } from '../../app-routing.module';

@Pipe({
  name: 'toProfileUrl',
})
export class ToProfileUrlPipe implements PipeTransform {
  private static readonly BASE_URL = location.origin;
  transform(employeeId: string): string {
    return `${ToProfileUrlPipe.BASE_URL}/${RoutePaths.EMPLOYEES}/details/${employeeId}`;
  }
}
