import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';

/**
 * Checks if the GraphQL response contains any errors.
 * If it does, an error will be thrown which can be caught and processed by using the `catchError` operator.
 * Otherwise, the response will be returned.
 */
export function throwGraphqlErrorsIfExists() {
  return function <T extends FetchResult>(source: Observable<T>): Observable<T> {
    return new Observable((subscriber) => {
      const subscription = source.subscribe({
        next(response) {
          if (response?.errors) {
            const unpackedMessage = response.errors.map((error) => error.message).join('\n');

            subscriber.error(unpackedMessage);
          } else {
            subscriber.next(response);
          }
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });

      return () => subscription.unsubscribe();
    });
  };
}
