import type * as Types from '../../models/types/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TeamsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TeamsQueryResult = { teams?: Array<{ id: string; name: string; shortName?: string | null } | null> | null };

export const TeamsDocument = gql`
  query Teams {
    teams(filter: { archived: false, lob: true }) {
      id
      name
      shortName
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TeamsQueryService extends Apollo.Query<TeamsQueryResult, TeamsQueryVariables> {
  document = TeamsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
