<app-dialog
  [titleText]="'common:dialogs.session_expired.title' | i18next"
  [messageText]="'common:dialogs.session_expired.message' | i18next"
  [showDialog]="showDialog$ | async"
  (submitEvent)="submit()"
  (cancelEvent)="cancel()"
>
  <div class="form">
    <app-dialog-buttons
      [submitText]="'common:dialogs.session_expired.submit' | i18next"
      [cancelText]="'common:dialogs.session_expired.cancel' | i18next"
      (cancelEvent)="cancel()"
      (submitEvent)="submit()"
      submitType="button"
    ></app-dialog-buttons>
  </div>
</app-dialog>
