import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '@shared/models/types/types.generated';

@Pipe({
  name: 'customerName',
})
export class CustomerNamePipe implements PipeTransform {
  transform(value: Customer, fallback: string): string {
    return value?.name ?? fallback;
  }
}
