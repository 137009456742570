import { inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NAVIGATION_ROUTE_CONFIG } from '@shared/tokens/navigation-route-config.token';
import { BehaviorSubject, filter, map, Subscription } from 'rxjs';
import { matchUUID } from '@shared/utils/utils';

@Injectable()
export class NavigationService implements OnDestroy {
  private _routes = inject(NAVIGATION_ROUTE_CONFIG);
  private _inRoute = new BehaviorSubject<string>(null);
  private inRoute$ = this._inRoute.asObservable();
  private subscription: Subscription;

  public inListRoute$ = this.inRoute$.pipe(map((route) => route === this._routes.listUrl));

  public inDetailRoute$ = this.inRoute$.pipe(map((route) => route === this._routes.detailUrl));

  public activeId$ = this.inRoute$.pipe(
    map((route) => route === this._routes.detailUrl),
    map((isDetailRoute) => (isDetailRoute ? this.extractIdFromRoute() : null)),
  );

  constructor(protected router: Router) {
    this.subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(({ url, urlAfterRedirects }: NavigationEnd) => this.matchRoute(url) ?? this.matchRoute(urlAfterRedirects)),
      )
      .subscribe((matchedRoute) => this._inRoute.next(matchedRoute));
  }

  public navigateToList = (): void => void this.router.navigate([this._routes.listUrl]);

  public navigateToDetail = (id: string, activateEditMode = false): void => {
    if (!this._routes.detailUrl) {
      console.info('No detailUrl is provided.');
      return;
    }

    const commands: unknown[] = [this._routes.detailUrl, id];

    if (activateEditMode) {
      commands.push({ edit: activateEditMode });
    }

    void this.router.navigate(commands);
  };

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private matchRoute(url: string): string | null {
    if (url === this._routes.listUrl || url.split('?')[0] === this._routes.listUrl) {
      return this._routes.listUrl;
    }

    if (this._routes.detailUrl && url.includes(this._routes.detailUrl)) {
      return this._routes.detailUrl;
    }

    return null;
  }

  protected extractIdFromRoute(): string | null {
    const url = this.router.url.replace(document.location.hash, '');

    const uuidMatch = matchUUID(url);

    if (!uuidMatch) return null;

    return uuidMatch[0];
  }
}
