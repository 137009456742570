import { I18NextModule as DefaultI18NextModule } from 'angular-i18next';

// german translation files
import commonDE from '../app/shared/locales/common.de.json';
import errorsDE from '../app/shared/locales/errors.de.json';
import employeesDE from '../app/employees/locales/employees.de.json';
import opportunitiesDE from '../app/opportunities/locales/opportunities.de.json';
import projectsDE from '../app/projects/locales/projects.de.json';
import chessDE from '../app/chess/locales/chess.de.json';
import aboutDE from '../app/about/locales/about.de.json';
import dashboardDE from '../app/dashboard/locales/dashboard.de.json';
import administrationDE from '../app/administration/locales/administration.de.json';

// english translation files
import commonEN from '../app/shared/locales/common.en.json';
import errorsEN from '../app/shared/locales/errors.en.json';
import employeesEN from '../app/employees/locales/employees.en.json';
import opportunitiesEN from '../app/opportunities/locales/opportunities.en.json';
import projectsEN from '../app/projects/locales/projects.en.json';
import chessEN from '../app/chess/locales/chess.en.json';
import aboutEN from '../app/about/locales/about.en.json';
import dashboardEN from '../app/dashboard/locales/dashboard.en.json';
import administrationEN from '../app/administration/locales/administration.en.json';

import { InitOptions } from 'i18next';
import { customFormatFunction } from '@shared/utils/i18n-utils';

export const i18NextConfig: InitOptions = {
  supportedLngs: ['de', 'en'],
  lng: 'de',
  fallbackLng: 'en',
  returnEmptyString: false,
  ns: ['common', 'errors', 'chess', 'employees', 'projects', 'opportunities', 'about', 'dashboard', 'administration'],
  defaultNS: 'common',
  interpolation: {
    format: DefaultI18NextModule.interpolationFormat(customFormatFunction),
  },
  resources: {
    de: {
      common: commonDE,
      errors: errorsDE,
      employees: employeesDE,
      opportunities: opportunitiesDE,
      projects: projectsDE,
      about: aboutDE,
      chess: chessDE,
      dashboard: dashboardDE,
      administration: administrationDE,
    },
    en: {
      common: commonEN,
      errors: errorsEN,
      employees: employeesEN,
      opportunities: opportunitiesEN,
      projects: projectsEN,
      about: aboutEN,
      chess: chessEN,
      dashboard: dashboardEN,
      administration: administrationEN,
    },
  },
};
