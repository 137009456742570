import { Component } from '@angular/core';
import { LoadingViewService } from '@shared/components/loading-view/loading-view.service';
import { PolidayService } from '@shared/services/poliday.service';
import { UserService } from '@shared/services/user.service';
import { VersionService } from '@shared/services/version.service';
import { of } from 'rxjs';
import { TeamsService } from '@shared/services/teams.service';
import { SessionExpirationWatcherService } from '@shared/services/session-expiration-watcher.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { is401Error } from '@shared/utils/utils';
import { NewVersionWatcherService } from '@shared/services/new-version-watcher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LoadingViewService],
})
export class AppComponent {
  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    private versionService: VersionService,
    private polidayService: PolidayService,
    private teamService: TeamsService,
    public loadingViewService: LoadingViewService,
    private sessionExpirationWatcherService: SessionExpirationWatcherService,
    private newVersionWatcherService: NewVersionWatcherService,
  ) {
    this.loadingViewService.loadingOn();
    this.userService
      .fetchUserInfo()
      .pipe(
        catchError((err: { networkError: HttpErrorResponse }) => {
          if (is401Error(err)) this.authenticationService.authenticate();

          return of(null);
        }),
      )
      .subscribe(() => {
        this.loadingViewService.loadingOff();
      });

    this.newVersionWatcherService.setupWatcher();
    this.sessionExpirationWatcherService.setupWatcher();

    this.versionService.migrateLocalStorage();
  }
}
