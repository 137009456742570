import { AccountQueryResult } from '@shared/services/generated/account.query.service.generated';
export type UserInfo = AccountQueryResult['account'];

export enum UserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_TEAMLEAD = 'ROLE_TEAMLEAD',
  ROLE_BIZDEV = 'ROLE_BIZDEV',
  ROLE_ALUMNI_MANAGER = 'ROLE_ALUMNI_MANAGER',
}
