import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorStateService {
  private _errorState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public $errorState: Observable<boolean> = this._errorState.asObservable();

  setErrorState = (state: boolean) => {
    this._errorState.next(state);
  };
}
