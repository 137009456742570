import { format as formatValue, isValid, parseISO, parseJSON } from 'date-fns';
import * as locales from 'date-fns/locale';

export const customFormatFunction = (value: string, format: string, lng: string = 'de'): string => {
  if (format === 'currency') {
    const digit = Number(value);

    return !isNaN(digit) ? digit.toLocaleString(lng, { style: 'currency', currency: 'EUR' }) : '-';
  }

  if (format.includes('maximumFractionDigits')) {
    const digit = Number(value);
    return !isNaN(digit)
      ? digit.toLocaleString(lng, {
          style: 'decimal',
          maximumFractionDigits: Number(format.split(':')[1] ?? 3),
        })
      : '-';
  }

  if (format === 'hours') {
    const digit = Number(value);
    return !isNaN(digit)
      ? digit.toLocaleString(lng, {
          style: 'decimal',
          maximumFractionDigits: 2,
        })
      : '-';
  }

  if (format === 'percent') {
    const digit = Number(value);
    return !isNaN(digit) ? digit.toLocaleString(lng, { style: 'percent' }) : '-';
  }

  const valueJsonDate = parseJSON(value);
  const valueIsoDate = parseISO(value);

  if (isValid(valueJsonDate)) {
    return format === 'shortDate'
      ? valueJsonDate.toLocaleDateString(lng, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : formatValue(valueJsonDate, format, {
          locale: locales[lng] as Locale,
        });
  }
  if (isValid(valueIsoDate)) {
    return format === 'shortDate'
      ? valueIsoDate.toLocaleDateString(lng, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : formatValue(valueIsoDate, format, {
          locale: locales[lng] as Locale,
        });
  }
  return value;
};
