import { Component } from '@angular/core';
import { AppDialogs, DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'app-new-version-dialog',
  templateUrl: './new-version-dialog.component.html',
  styleUrls: ['./new-version-dialog.component.scss'],
})
export class NewVersionDialogComponent {
  readonly showDialog$ = this.dialogService.observeDialog(AppDialogs.NEW_VERSION_AVAILABLE);

  constructor(private dialogService: DialogService) {}

  cancel() {
    this.dialogService.closeDialog(AppDialogs.NEW_VERSION_AVAILABLE);
  }

  submit() {
    window.location.reload();
  }
}
