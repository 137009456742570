import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('inoDialog', { read: ElementRef })
  inoDialog: ElementRef<HTMLInoDialogElement>;

  @Input() titleText: string;

  @Input() width: CSSStyleDeclaration['width'] = '550px';
  @Input() maxWidth: CSSStyleDeclaration['maxWidth'];

  /**
   * Use this or the default slot
   */
  @Input() messageText: string;
  @Input() buttonState: boolean;
  @Input() showDialog: boolean;
  @Input() isLoading = false;
  @Output() cancelEvent = new EventEmitter();

  private static readonly INO_DIALOG_WIDTH_VARIABLE_NAME = '--ino-dialog-width';

  ngAfterViewInit() {
    this.inoDialog.nativeElement.style.setProperty(DialogComponent.INO_DIALOG_WIDTH_VARIABLE_NAME, this.width);

    // set max width of <ino-dialog> manually as it is not officially supported yet
    if (this.maxWidth) {
      const style = document.createElement('style');
      style.innerHTML = `.mdc-dialog__container { max-width: ${this.maxWidth}}`;
      this.inoDialog.nativeElement.shadowRoot?.appendChild(style);
    }
  }

  ngOnDestroy() {
    this.inoDialog.nativeElement?.remove();
  }

  cancelDialog(): void {
    this.cancelEvent.emit();
  }
}
