import { InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppConfig } from '@shared/models/interfaces/app-config';
import { GraphQLModule } from './graphql.module';
import { I18nModule } from './i18n.module';
import { SentryModule } from './sentry.module';

export const APP_CONFIG: InjectionToken<AppConfig> = new InjectionToken<AppConfig>('app-config.token');

@NgModule({
  exports: [GraphQLModule, SentryModule, I18nModule],
})
export class ConfigurationModule {
  constructor(@Optional() @SkipSelf() parent?: ConfigurationModule) {
    if (parent) {
      throw new Error(
        'The ConfigurationModule should only be loaded once in the AppModule. Do not re-import it in feature modules.',
      );
    }
  }
}
