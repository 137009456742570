import { Injectable } from '@angular/core';
import { BehaviorSubject, pipe } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { concat, without } from 'lodash-es';

export enum AppDialogs {
  REFRESH_SESSION,
  NEW_VERSION_AVAILABLE,
}

@Injectable()
export class DialogService<Dialogs = AppDialogs> {
  private _dialogs = new BehaviorSubject<Dialogs[]>([]);
  dialogs$ = this._dialogs.asObservable().pipe(shareReplay(1));

  observeDialog = (dialog: Dialogs): Observable<boolean> =>
    this.dialogs$.pipe(pipe(map((openDialogs) => openDialogs.includes(dialog))));

  showDialog(dialog: Dialogs): void {
    const current = this._dialogs.getValue();
    if (current.includes(dialog)) return;
    this._dialogs.next(concat(current, [dialog]));
  }

  closeDialog(dialog: Dialogs): void {
    this._dialogs.next(without(this._dialogs.getValue(), dialog));
  }
}
