import { Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { AppDialogs, DialogService } from '@shared/services/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interval, mapTo, of, switchMap } from 'rxjs';
import { catchError, filter, withLatestFrom } from 'rxjs/operators';
import { is401Error } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SessionExpirationWatcherService {
  private static REFRESH_INTERVAL_SECONDS = 60;
  private isDialogOpen$ = this.dialogService.observeDialog(AppDialogs.REFRESH_SESSION);

  constructor(
    private userService: UserService,
    private dialogService: DialogService,
  ) {}

  setupWatcher() {
    interval(SessionExpirationWatcherService.REFRESH_INTERVAL_SECONDS * 1000)
      .pipe(
        withLatestFrom(this.isDialogOpen$),
        filter(([, isDialogOpen]) => !isDialogOpen),
        switchMap(() =>
          this.userService.fetchUserInfo().pipe(
            mapTo(false), // succesfull fetch
            catchError((err: { networkError: HttpErrorResponse }) => of(is401Error(err))), // error while fetching
          ),
        ),
        filter((is401) => is401),
      )
      .subscribe(() => this.dialogService.showDialog(AppDialogs.REFRESH_SESSION));
  }
}
