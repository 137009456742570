import { FormGroup } from '@angular/forms';
import {
  FilterNodeComponent,
  FilterNodeFormModel,
} from '@shared/components/advanced-filter/filter-node/filter-node.component';
import { AdvancedFilterNode } from '@shared/models/types/advanced-filter-input';
import { FilterNodeType } from '@shared/models/types/types.generated';
import { Injectable } from '@angular/core';

@Injectable()
export class FilterNodeService {
  public mapToFilterNode<T>(formGroup: FormGroup<FilterNodeFormModel<T>>): AdvancedFilterNode<T> {
    const v = formGroup.value;
    const children = formGroup.controls.children?.controls;

    const filterNode: AdvancedFilterNode<T> = {
      type: v.type,
    };

    if (children.length > 0) {
      filterNode.children = children.map((node) => this.mapToFilterNode(node));
    }

    if (v.value) {
      filterNode.value = v.value && {
        key: v.value.key,
        operator: v.value.operator,
        value: v.value.value,
      };
    }

    return filterNode;
  }

  public mapToFormGroup<T>(filterNode: AdvancedFilterNode<T>): FormGroup<FilterNodeFormModel<T>> {
    const type = filterNode.type;
    const nodeGroup = FilterNodeComponent.createNodeGroup<T>(type);
    if (type !== FilterNodeType.Leaf && filterNode.children.length > 0) {
      filterNode.children.forEach((node) => nodeGroup.controls.children.push(this.mapToFormGroup<T>(node)));
    }
    if (type === FilterNodeType.Leaf) {
      nodeGroup.controls.value.patchValue({
        key: filterNode.value.key,
        operator: filterNode.value.operator,
        value: filterNode.value.value,
      });
    }

    return nodeGroup;
  }
}
