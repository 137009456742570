import { Injectable } from '@angular/core';
import { FilterEntity } from '@shared/types';

@Injectable()
export abstract class BaseFilterService<T> {
  abstract readonly filters$: Observable<T>;
  abstract readonly searchTerm$: Observable<string>;
  readonly entity: FilterEntity;

  abstract filters: T;

  abstract searchTerm: string;

  abstract resetFilters(): void;

  abstract setPartialFilters(filters: Partial<T>): void;

  abstract updateSearchTerm(searchTerm: string): void;

  public abstract isFilterKey(key: string): boolean;
}
