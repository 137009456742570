import { Injectable } from '@angular/core';
import { ViewModeUnion } from '@inovex.de/elements';

export enum CacheKey {
  APP_VERSION = 'APP_VERSION',
  NAVBAR_IS_COLLAPSED = 'NAVBAR_IS_COLLAPSED',
  FILTER_SIDEBAR_IS_COLLAPSED = 'FILTER_SIDEBAR_IS_COLLAPSED',
  FAB_SET_IS_COLLAPSED = 'FAB_SET_IS_COLLAPSED',
  MARKDOWN_EDITOR_VIEW_MODE = 'MARKDOWN_EDITOR_VIEW_MODE',
  OPPORTUNITY_FILTER_BOXES_COLLAPSED = 'OPPORTUNITY_FILTER_BOXES_COLLAPSED',
  PROJECT_FILTER_BOXES_COLLAPSED = 'PROJECT_FILTER_BOXES_COLLAPSED',
  EMPLOYEE_FILTER_BOXES_COLLAPSED = 'EMPLOYEE_FILTER_BOXES_COLLAPSED',
}

interface CacheValues {
  [CacheKey.APP_VERSION]: string;
  [CacheKey.NAVBAR_IS_COLLAPSED]: boolean;
  [CacheKey.FILTER_SIDEBAR_IS_COLLAPSED]: boolean;
  [CacheKey.FAB_SET_IS_COLLAPSED]: boolean;
  [CacheKey.MARKDOWN_EDITOR_VIEW_MODE]: ViewModeUnion;
  [CacheKey.OPPORTUNITY_FILTER_BOXES_COLLAPSED]: string[];
  [CacheKey.PROJECT_FILTER_BOXES_COLLAPSED]: string[];
  [CacheKey.EMPLOYEE_FILTER_BOXES_COLLAPSED]: string[];
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _localStorage: Storage;

  constructor() {
    this._localStorage = window.localStorage;
  }

  saveKey<T extends CacheKey>(key: T, value: CacheValues[T]): boolean {
    if (!this.isLocalStorageSupported) {
      return false;
    }

    this._localStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  loadKey<T extends CacheKey>(key: T): CacheValues[T] | null;

  loadKey<T extends CacheKey>(key: T, fallback: CacheValues[T]): CacheValues[T];

  // only the signatures above will be visible from outside
  loadKey<T extends CacheKey>(key: T, fallback?: CacheValues[T]): CacheValues[T] | null {
    if (!this.isLocalStorageSupported) {
      return fallback ?? null;
    }

    const item = this._localStorage.getItem(key);

    // key not initialized yet
    if (item === null && fallback != null) {
      this.saveKey(key, fallback);
      return fallback;
    }

    if (item === null) return null;

    return JSON.parse(item) as CacheValues[T];
  }

  get isLocalStorageSupported(): boolean {
    return !!this._localStorage;
  }
}
