import { Pipe, PipeTransform } from '@angular/core';
import { EmploymentStatus } from '@shared/models/types/types.generated';
import { I18NextPipe } from 'angular-i18next';

export type EmploymentStatusNameOptions = {
  onlyDetail?: boolean;
};

@Pipe({
  name: 'employmentStatusName',
})
export class EmploymentStatusNamePipe implements PipeTransform {
  constructor(private i18NextPipe: I18NextPipe) {}
  transform(value: EmploymentStatus, options?: EmploymentStatusNameOptions): string {
    if (!value) return this.i18NextPipe.transform('common:notSpecified');
    if (value.name === value.detail) {
      return value.name;
    }
    if (options?.onlyDetail) {
      return value.detail;
    }
    return value.name + ' - ' + value.detail;
  }
}
