import { Injectable } from '@angular/core';
import { ListService } from '@shared/services/list.service';
import { BehaviorSubject } from 'rxjs';
import { BaseFilterService } from '@shared/services/base-filter.service';

export enum FilterStrategy {
  ADVANCED,
}

@Injectable()
export abstract class FilterStrategyService {
  private _activeListService$ = new BehaviorSubject<ListService<any, any>>(this.advancedListService);
  private _activeFilterService$ = new BehaviorSubject<BaseFilterService<any>>(this.advancedFilterService);
  private _activeStrategy$ = new BehaviorSubject<FilterStrategy>(FilterStrategy.ADVANCED);

  public readonly activeStrategy = () => this._activeStrategy$.getValue();
  public readonly activeStrategy$ = this._activeStrategy$.asObservable();

  public readonly activeListService = () => this._activeListService$.getValue();
  public readonly activeListService$ = this._activeListService$.asObservable();

  public readonly activeFilterService = () => this._activeFilterService$.getValue();
  public readonly activeFilterService$ = this._activeFilterService$.asObservable();

  protected constructor(
    private advancedListService: ListService<any, any>,
    private advancedFilterService: BaseFilterService<any>,
  ) {}
}
