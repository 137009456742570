import {
  EmployeeAbsenceInterval,
  EmployeeStatus,
  EmploymentPeriod,
  EmploymentStatus,
  Label,
  Location,
  Team,
} from '@shared/models/types/types.generated';
import { isNil, omitBy, partition, pick, round, sum } from 'lodash-es';
import { parseDate, sortRatedSkills } from '@shared/utils/utils';
import { RatedSkill } from './rated-skill';
import { IdentifiableEntity } from '@shared/models/interfaces/identifiable-entity';
import { EmployeesAdvancedQueryResult } from '@employees/services/generated/employees.service.generated';
import { AdditionalEmployeeStatus } from '@shared/models/types/additional-employee-status';
import { compareDesc } from 'date-fns';
import { isEmployeeAway } from '@shared/utils/employee-profile';

type TeamLead = EmploymentPeriod['teamLead'];

export abstract class Employee implements IdentifiableEntity {
  id: string;
  firstName: string;
  lastName: string;
  status: EmployeeStatus;
  otherSkills: RatedSkill[] = [];

  // Frontend Props
  mainSkills: RatedSkill[] = [];
  skills: RatedSkill[] = [];
  isAway: boolean = false;
  isOverplanned: boolean;
  employmentStatus: EmploymentStatus;
  workingHours: [number, number, number, number, number, number, number];
  sumWorkingHours: number = 0;
  fte: number = 0;
  mainTeam: Pick<Team, 'id' | 'name' | 'shortName'>;
  teamLead: TeamLead;
  mobile: string;
  location: Location;
  email: string | null;
  userId: string;
  salesProfileGoogleDriveId: string | null;
  salesProfileLastModified: Date | null;
  isIntern: boolean;
  mostRelevantEmploymentPeriod: EmploymentPeriod;
  absenceIntervals: EmployeeAbsenceInterval[] = [];
  employmentPeriods: Pick<EmploymentPeriod, 'from' | 'to'>[] = [];
  readonly contractStartingDate: Date | null = null;
  readonly contractExpirationDate: Date | null = null;
  labels: Label[] = [];

  protected constructor({
    skills,
    status,
    mostRelevantEmploymentPeriod,
    availability,
    ...employeDto
  }: EmployeesAdvancedQueryResult['queryEmployees'][number]) {
    Object.assign(this, omitBy(employeDto, isNil));

    this.setSkills((skills as RatedSkill[]) ?? []);
    this.mainTeam = Employee.getMainTeam(mostRelevantEmploymentPeriod.team);
    this.setWorkingHours([
      mostRelevantEmploymentPeriod.workingHoursMonday,
      mostRelevantEmploymentPeriod.workingHoursTuesday,
      mostRelevantEmploymentPeriod.workingHoursWednesday,
      mostRelevantEmploymentPeriod.workingHoursThursday,
      mostRelevantEmploymentPeriod.workingHoursFriday,
      mostRelevantEmploymentPeriod.workingHoursSaturday,
      mostRelevantEmploymentPeriod.workingHoursSunday,
    ]);

    this.isAway = isEmployeeAway(employeDto.absenceIntervals);

    this.location = mostRelevantEmploymentPeriod.location;
    this.status = status;
    this.isOverplanned = availability?.isOverplanned || false;
    this.employmentStatus = mostRelevantEmploymentPeriod.employmentStatus;
    this.teamLead = mostRelevantEmploymentPeriod.teamLead;
    this.salesProfileLastModified = employeDto.salesProfileLastModified
      ? new Date(employeDto.salesProfileLastModified as string)
      : null;
    this.isIntern = !mostRelevantEmploymentPeriod.billable as boolean;
    this.mostRelevantEmploymentPeriod = mostRelevantEmploymentPeriod;

    if (this.status === EmployeeStatus.Future) {
      this.contractStartingDate = parseDate(mostRelevantEmploymentPeriod.from as string);
    }

    if (this.status === EmployeeStatus.Past) {
      this.contractExpirationDate = parseDate(mostRelevantEmploymentPeriod.to as string);
      this.salesProfileLastModified = employeDto.salesProfileLastModified
        ? new Date(employeDto.salesProfileLastModified as string)
        : null;
    }

    if (this.status === EmployeeStatus.Active) {
      const lastWorkingDay: Date | null = this.employmentPeriods.sort((a, b) => {
        const fromA = parseDate(a.from as string);
        const fromB = parseDate(b.from as string);
        return compareDesc(fromA, fromB);
      })[0]?.to as Date | null;

      this.contractExpirationDate = lastWorkingDay ? parseDate(lastWorkingDay) : null;
    }
  }

  get combinedStatus(): EmployeeStatus | AdditionalEmployeeStatus {
    return this.isOverplanned
      ? AdditionalEmployeeStatus.OverPlanned
      : this.isAway
        ? AdditionalEmployeeStatus.Away
        : this.status;
  }

  setSkills(ratedSkills: RatedSkill[]) {
    const mappedSkills = ratedSkills.map((skill) => pick(skill, ['name', 'rating']));
    const sortedSkills = sortRatedSkills(mappedSkills) as RatedSkill[];

    const [mainSkills, otherSkills] = partition(sortedSkills, Employee.isMainSkill);

    this.otherSkills = otherSkills;
    this.mainSkills = mainSkills;
    this.skills = sortedSkills;
  }

  static getMainTeam(team: Team) {
    const { id, shortName, name } = team.parent === null || team.parent?.shortName === 'inovex' ? team : team.parent;
    return {
      id,
      name,
      shortName: Employee.removeLOBPrefix(shortName),
    };
  }

  setWorkingHours(workingHours: [number, number, number, number, number, number, number]) {
    this.workingHours = workingHours;
    this.sumWorkingHours = sum(workingHours);
    this.fte = Employee.calcFTE(this.sumWorkingHours);
  }

  private static readonly LoB_PREFIX = 'LoB-';

  public static calcFTE(workingHoursSum: number): number {
    if (workingHoursSum === 0) return 0;

    return round(workingHoursSum / 40, 2);
  }

  private static removeLOBPrefix(name: string): string {
    if (!name.includes(Employee.LoB_PREFIX)) return name;

    return name.replace(Employee.LoB_PREFIX, '');
  }

  public static isMainSkill({ name }: RatedSkill): boolean {
    return name.startsWith('__') && name.endsWith('__');
  }

  matchesIdentity(identifier: string): boolean {
    return [this.id, this.email, this.userId].some((match) => identifier === match);
  }
}
