import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  template: 'empty-template',
})
export class UnsubscribeComponent implements OnDestroy {
  public destroy$ = new Subject<void>();
  protected _subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
