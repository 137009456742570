import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundErrorComponent } from '@shared/components/not-found-error/not-found-error.component';
import { RouteCanMatch } from '@shared/services/route-can-match.service';
import { QuicklinkStrategy } from 'ngx-quicklink';

export enum RoutePaths {
  HOME = '',
  DASHBOARD = 'dashboard',
  OPPORTUNITIES = 'opportunities',
  PROJECTS = 'projects',
  EMPLOYEES = 'employees',
  CHESS = 'chess',
  ADMINISTRATION = 'administration',
  ABOUT = 'about',
}

export const routes: Routes = [
  {
    path: RoutePaths.DASHBOARD,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canMatch: [RouteCanMatch],
  },
  {
    path: RoutePaths.OPPORTUNITIES,
    loadChildren: () => import('./opportunities/opportunities.module').then((m) => m.OpportunitiesModule),
    canMatch: [RouteCanMatch],
  },

  {
    path: RoutePaths.PROJECTS,
    loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    canMatch: [RouteCanMatch],
  },
  {
    path: RoutePaths.EMPLOYEES,
    loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule),
    canMatch: [RouteCanMatch],
  },
  {
    path: RoutePaths.CHESS,
    loadChildren: () => import('./chess/chess.module').then((m) => m.ChessModule),
    canMatch: [RouteCanMatch],
  },
  {
    path: RoutePaths.ADMINISTRATION,
    loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
    canMatch: [RouteCanMatch],
  },
  {
    path: RoutePaths.ABOUT,
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    canMatch: [RouteCanMatch],
  },
  {
    path: RoutePaths.HOME,
    redirectTo: RoutePaths.DASHBOARD,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
