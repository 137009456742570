import { AppConfig } from '@shared/models/interfaces/app-config';

type ReleaseConfig = Pick<AppConfig, 'release'>;
type RemainingConfig = Omit<AppConfig, 'release'>;

export class AppConfigService {
  public static APP_CONFIG_PATH = '/assets/config/app-config.json';
  public static APP_VERSION_PATH = '/assets/config/app-version.json';

  public static APP_CONFIG: RemainingConfig = null;

  public static async loadAppConfig(): Promise<AppConfig> {
    const responses = await Promise.all([AppConfigService.fetchAppConfig(), AppConfigService.fetchAppVersion()]);
    const [config, version] = await Promise.all(responses);
    AppConfigService.APP_CONFIG = config;
    return { ...config, ...version } as AppConfig;
  }

  private static fetchAppConfig(): Promise<RemainingConfig> {
    return fetch(AppConfigService.APP_CONFIG_PATH, { cache: 'no-store' }).then(
      (resp) => resp.json() as unknown as RemainingConfig,
    );
  }

  public static fetchAppVersion(): Promise<ReleaseConfig> {
    return fetch(AppConfigService.APP_VERSION_PATH, { cache: 'no-store' }).then(
      (resp) => resp.json() as unknown as ReleaseConfig,
    );
  }
}
