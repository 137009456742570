import { RoutePaths } from '../app-routing.module';

export type Placement = 'left' | 'top' | 'right' | 'bottom';

export enum GraphQLErrorMessage {
  INTERNAL_SERVER_ERROR = 'Internal server error',
  NOT_FOUND = 'Not Found',
  FORBIDDEN = 'Forbidden',
}

export enum FilterEntity {
  PROJECTS = RoutePaths.PROJECTS,
  OPPORTUNITIES = RoutePaths.OPPORTUNITIES,
  EMPLOYEES = RoutePaths.EMPLOYEES,
  CHESS = RoutePaths.CHESS,
}
