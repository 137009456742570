import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { I18NextPipe } from 'angular-i18next';
import { isEqual } from 'date-fns';
import TimeRange from '@shared/models/types/time-range';
import { parseDate } from '@shared/utils/utils';

enum DateFormats {
  DEFAULT = 'dd.LL.yy',
  NO_YEAR = 'dd.LL',
  LONG_YEAR = 'dd.LL.yyyy',
  WEEKDAY_DEFAULT = 'EEE dd.LL.yy',
}

@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  readonly NOT_AVAILABLE = this.i18nPipe.transform('common:valueNotSpecifiedAbbr');

  constructor(
    private datePipe: DatePipe,
    private i18nPipe: I18NextPipe,
  ) {}

  transform(range: TimeRange, format: keyof typeof DateFormats = 'DEFAULT', omitSameYear = false): string {
    const dateFormat = DateFormats[format];

    if (range.from == null && range.to == null) return this.NOT_AVAILABLE;

    const from = parseDate(range.from);
    const to = parseDate(range.to);

    const fromString = this.datePipe.transform(from, dateFormat);

    if (isEqual(from, to)) return fromString;

    if (to == null) return `${fromString} - ${this.NOT_AVAILABLE}`;

    if (omitSameYear && format === 'DEFAULT' && from.getFullYear() === to.getFullYear()) {
      return `${this.datePipe.transform(from, DateFormats.NO_YEAR)} - ${this.datePipe.transform(to, DateFormats.DEFAULT)}`;
    }
    return `${this.datePipe.transform(from, dateFormat)} - ${this.datePipe.transform(to, dateFormat)}`;
  }
}
