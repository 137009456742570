import { Component } from '@angular/core';
import { AppDialogs, DialogService } from '@shared/services/dialog.service';
import { AuthenticationService } from '@shared/services/authentication.service';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss'],
})
export class SessionExpiredDialogComponent {
  readonly showDialog$ = this.dialogService.observeDialog(AppDialogs.REFRESH_SESSION);

  constructor(
    private dialogService: DialogService,
    private authenticationService: AuthenticationService,
  ) {}

  cancel() {
    this.dialogService.closeDialog(AppDialogs.REFRESH_SESSION);
  }

  submit() {
    this.authenticationService.authenticate();
  }
}
