<fieldset class="filter-box" [class.filter-box--loading]="loading">
  @if (useCustomHeader) {
    <div class="filter-box__header">
      <ng-content select="[header]"></ng-content>
    </div>
  } @else {
    <legend class="filter-box__title">{{ title }}</legend>
  }

  @if (descriptionShort || descriptionLong) {
    <div class="filter-box__description flex--row" [id]="title">
      @if (descriptionShort) {
        <small>{{ descriptionShort }}</small>
      }
      @if (descriptionLong) {
        <ng-container>
          <ino-icon icon="info"></ino-icon>
          <ino-tooltip [for]="title" delay="300" colorScheme="light">
            {{ descriptionLong }}
          </ino-tooltip>
        </ng-container>
      }
    </div>
  }
  @if (loading) {
    <ino-spinner class="filter-box__spinner" type="circle"> </ino-spinner>
  }
  <ino-icon
    [icon]="collapsed ? 'arrow_down' : 'arrow_up'"
    class="filter-box__title-icon"
    [attr.aria-label]="'common:sidebar.filter.' + ariaLabel | i18next"
    (clickEl)="onToggleCollapsed()"
    clickable
  ></ino-icon>
  <div class="filter-box__content" [class.filter-box__content--expanded]="!collapsed">
    @if (!collapsed) {
      <ng-content></ng-content>
    }
  </div>
</fieldset>
