import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IdentifiableEmployee } from '@shared/models/types/identifiable-employee';
import { BehaviorSubject } from 'rxjs';
import { combineLoadingStates } from '@shared/utils/rxjs';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
  @Input() employee: {
    id: string;
    firstName?: string;
    lastName?: string;
  } | null = null;
  @Input() metadataPosition: 'bottom-right' | 'top-right' = 'bottom-right';
  @Input() error = false;
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() disableProfileLink = false;

  @Input() set isLoading(loading: boolean) {
    this._isDataLoading.next(loading);
  }

  @Output() removeAvatarEvent = new EventEmitter<string>();

  private _isImgLoading = new BehaviorSubject(false); // img is fetching
  private _isDataLoading = new BehaviorSubject(false); // employee data is loading

  isAnythingLoading$ = combineLoadingStates([this._isImgLoading.asObservable(), this._isDataLoading.asObservable()]);

  imageDidLoad() {
    this._isImgLoading.next(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.employee) return;

    const prevEmployee = changes.employee.previousValue as IdentifiableEmployee;
    const currentEmployee = changes.employee.currentValue as IdentifiableEmployee;

    if (currentEmployee === null) {
      this._isImgLoading.next(false);
      return;
    }

    if (!prevEmployee || prevEmployee.id !== currentEmployee.id) this._isImgLoading.next(true);
  }
}
