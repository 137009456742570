import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { I18NEXT_SERVICE, I18NextLoadResult, I18NextModule, ITranslationService } from 'angular-i18next';
import { i18NextConfig } from '@config/i18next.config';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

export const initI18nConfig = (i18next: ITranslationService) => (): Promise<I18NextLoadResult> =>
  i18next.init(i18NextConfig);

export const getLocaleID = (i18next: ITranslationService): string => i18next.language;

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: initI18nConfig,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: getLocaleID,
  },
];

@NgModule({
  imports: [I18NextModule.forRoot()],
  exports: [I18NextModule],
  declarations: [],
  providers: [I18N_PROVIDERS],
})
export class I18nModule {}
