import {
  addDays,
  compareAsc,
  differenceInBusinessDays,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  isAfter,
  isEqual,
  isWeekend,
  isWithinInterval,
  startOfMonth,
  subDays,
} from 'date-fns';
import { first, last } from 'lodash-es';
import TimeRange from '@shared/models/types/time-range';
import { EmployeeAbsenceInterval } from '@shared/models/types/types.generated';
import { AssignmentIntervals } from '@shared/models/types/assignment-intervals';
import { sortDateAsc } from '@shared/utils/date-utils';
import { parseDate } from './utils';
const GRID_START_COL = 1;

// INCA_WEEKDAYS (index of workingHours) Monday - Sunday
// DATE_FNS_WEEKDAYS = Sunday - Saturday. See https://date-fns.org/v2.30.0/docs/getDay#returns
// To map the INCA_WEEKDAYS to the DATE_FNS_WEEKDAYS
export const WEEKDAYS: Record<number, number> = {
  0: 1, // Monday
  1: 2, // Tuesday
  2: 3, // Wednesday
  3: 4, // Thursday
  4: 5, // Friday
  5: 6, // Saturday
  6: 0, // Sunday
};

/**
 * Jede Column in der Timeline steht für ein Datum.
 * Um also die passende Column zu einem Date zu berechnen, muss der Abstand zwischen
 * dem ersten Datum aus der Timeline und dem zu konvertierenden Datum berechnet werden.
 * Zusätzlich muss +1 (GRID_START_COL) gerechnet werden, da Grids bei 1 anfangen.
 *
 * @param firstDate Erstes Datum der Timeline, welches als Berechnungsgrundlage verwendet wird
 * @param date Datum, welches in eine Columnn (X-Koordinate) im Grid umgewandelt werden soll
 */
export function convertDateToColumn(firstDate: Date, date: Date): number {
  return differenceInDays(date, firstDate) + GRID_START_COL;
}

export function calcFirstTimelineDate(startDate: string): Date;

export function calcFirstTimelineDate(startDate: Date): Date;

export function calcFirstTimelineDate(startDate: Date | string): Date {
  if (typeof startDate === 'string') return startOfMonth(new Date(startDate));

  return startOfMonth(startDate);
}

export function buildAbsenceIntervals(dates: Date[], noWorkingDays: Date[] = []): TimeRange[] {
  return findAbsenceIntervals(
    dates.filter((date) => !isWeekend(date)),
    noWorkingDays,
  );
}

export function calcWeeklyAbsenceInInterval(
  from: Date,
  to: Date,
  weekday: number, // 0 - 6, Mo - Su
): Date[] {
  const intervalDays = eachDayOfInterval({ start: from, end: endOfMonth(to) });
  const days: Date[] = [];

  intervalDays.forEach((day) => {
    if (WEEKDAYS[weekday] === getDay(day)) {
      days.push(day);
    }
  });

  return days;
}

/**
 * Reduces a sorted array of dates to intervals.
 * e.g. ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-15']
 *       => [{from: '2022-01-01', to: '2022-01-03'}, {from: '2022-01-15', to: '2022-01-15'}]
 * @param absenceDates
 * @param noWorkingDays
 */
function findAbsenceIntervals(absenceDates: Date[], noWorkingDays: Date[] = []): TimeRange[] {
  if (absenceDates.length === 0) return [];

  const sortedDates: Date[] = absenceDates.sort(compareAsc);

  const intervals: Date[][] = [[]];

  for (const date of sortedDates) {
    // reference to last array in intervals to let the interval grow
    const currBucket: Date[] = last(intervals);

    if (currBucket.length === 0) {
      currBucket.push(date);
    } else {
      const lastDayInBucket: Date = last(currBucket);

      // check if its a single absence day or an interval (if previous day is an working day)
      if (isPrevBusinessDayOrDayOff(date, lastDayInBucket, noWorkingDays) || isEqual(date, lastDayInBucket)) {
        // if multiple absences on same day
        // push to currBucket = add item to the last array (interval) in intervals = let the interval grow
        currBucket.push(date);
      } else {
        // push to intervals = create new interval
        intervals.push([date]);
      }
    }
  }

  return intervals.map((range) => ({ from: first(range), to: last(range) }));
}

function isPrevBusinessDayOrDayOff(date: Date, dateBefore: Date, noWorkingDays: Date[] = []): boolean {
  const businessDaysBetweenCounter = differenceInBusinessDays(date, dateBefore);
  const businessDaysBetweenInterval = eachDayOfInterval({
    start: dateBefore,
    end: date,
  }).filter((day) => !isEqual(date, day) && !isEqual(dateBefore, day) && !isWeekend(day));

  let isBetween = false;

  // Between Mo and Tue is 1 working day
  // Between Mo and Wed are 2 working days and so on..
  if (noWorkingDays.length && businessDaysBetweenCounter > 1) {
    // to count the days within the interval (businessDaysBetweenInterval) AND in the array of noWorkingDays
    let noWorkingDaysCounter: number = 0;

    businessDaysBetweenInterval.forEach((day) => {
      noWorkingDays.forEach((noWorkingDay) => {
        if (isEqual(day, noWorkingDay)) {
          noWorkingDaysCounter++;
        }
      });
    });

    // if the numbers are equal, every day within the interval (businessDaysBetweenInterval) are included and the date (date) should be added to the interval
    if (noWorkingDaysCounter === businessDaysBetweenInterval.length) {
      isBetween = true;
    }
  }

  return isBetween || businessDaysBetweenCounter === 1;
}

export const calcAssignmentIntervals = (
  assignmentStart: Date,
  assignmentEnd: Date,
  absenceIntervals: EmployeeAbsenceInterval[] = [],
): AssignmentIntervals[] => {
  const intervals = calcEmployeeAbsenceIntervals(assignmentStart, assignmentEnd, absenceIntervals);

  if (!intervals || intervals.length === 0) {
    return [
      {
        from: assignmentStart,
        to: assignmentEnd,
        isAbsence: false,
      },
    ];
  }

  const assignmentIntervals: AssignmentIntervals[] = [];

  for (let i = 0; i < intervals.length; i++) {
    const currAbsence: AssignmentIntervals = {
      ...intervals[i],
      isAbsence: true,
    };
    const dayBeforeCurrAbsence = subDays(currAbsence.from, 1);
    const dayAfterCurrAbsence = addDays(currAbsence.to, 1);

    if (i === 0) {
      // edge case: assignmentStart is absence day
      if (isAfter(dayBeforeCurrAbsence, assignmentStart)) {
        assignmentIntervals.push({
          from: assignmentStart,
          to: dayBeforeCurrAbsence,
          isAbsence: false,
        });
      }
    } else {
      const prevAbsence = last(assignmentIntervals);
      const dayAfterPrevAbsence = addDays(prevAbsence.to, 1);

      assignmentIntervals.push({
        from: dayAfterPrevAbsence,
        to: dayBeforeCurrAbsence,
        isAbsence: false,
      });
    }

    assignmentIntervals.push(currAbsence);

    if (
      i === absenceIntervals.length - 1 &&
      !isAfter(dayAfterCurrAbsence, assignmentEnd) // edge case: assignmentEnd is absence day
    ) {
      assignmentIntervals.push({
        from: dayAfterCurrAbsence,
        to: assignmentEnd,
        isAbsence: false,
      });
    }
  }
  return assignmentIntervals;
};

const calcEmployeeAbsenceIntervals = (
  assignmentStart: Date,
  assignmentEnd: Date,
  absenceIntervals?: EmployeeAbsenceInterval[],
): TimeRange[] => {
  if (!assignmentEnd) return [];

  if (!absenceIntervals) return [];

  const relevantDates = getAllWorkdaysWithinAbsenceIntervals(absenceIntervals)
    .filter((date: Date) => isWithinInterval(date, { start: assignmentStart, end: assignmentEnd }))
    .sort(sortDateAsc); // absences from backend are not always sorted

  return buildAbsenceIntervals(relevantDates);
};

export const getAllWorkdaysWithinAbsenceIntervals = (intervals: EmployeeAbsenceInterval[]): Date[] =>
  intervals
    .map((interval: EmployeeAbsenceInterval) =>
      eachDayOfInterval({
        start: parseDate(interval.start as Date),
        end: parseDate(interval.end as Date),
      }),
    )
    .flat()
    .filter((day: Date) => !isWeekend(day));

export const isBusinessDay = (day: number) => WEEKDAYS[day] !== 0 && WEEKDAYS[day] !== 6;
