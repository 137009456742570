import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
})
export class FilterBoxComponent implements OnChanges {
  @Input() title: string;

  /**
   * Text that will be shown next to the title
   */
  @Input() descriptionShort: string;

  /**
   * If defined, will render an info-icon with a tooltip including this text
   */
  @Input() descriptionLong: string;
  @Input() collapsed = false;
  @Input() loading = false;

  /**
   * If true, will render content with "header" tag instead of the usual title
   */
  @Input() useCustomHeader = false;

  @Output() collapsedChange = new EventEmitter<boolean>();

  ariaLabel: string;

  ngOnChanges(): void {
    this.ariaLabel = this.collapsed ? 'openGroup' : 'closeGroup';
  }

  onToggleCollapsed = (): void => {
    this.collapsedChange.emit(!this.collapsed);
  };
}
