import { format, isAfter, isBefore, isMatch } from 'date-fns';

export const isISODateStr = (date: string): boolean => isMatch(date, 'yyyy-MM-dd');

export const toISODateStr = (date: Date): string | null => {
  if (!isValidDate(date)) return null;

  return format(date, 'yyyy-MM-dd');
};

const isValidDate = (d: Date) => d instanceof Date && !isNaN(d.getTime());

export const sortDateAsc = (a: Date, b: Date) => (isBefore(a, b) ? -1 : isAfter(a, b) ? 1 : 0);

export const formatToYearMonth = (date: Date) => format(date, 'yyyy-MM');
