import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import {
  differenceInDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  getWeek,
  isMonday,
  isWeekend,
  isWithinInterval,
  startOfMonth,
} from 'date-fns';
import { convertDateToColumn } from '@shared/utils/timeline-utils';
import { first, last } from 'lodash-es';
import { Subject } from 'rxjs';

type GridEntry = {
  col: number;
};

type Month = GridEntry & {
  name: string;
  year: string;
};

type Monday = GridEntry & {
  text: string;
  kw: string;
};

type NoWorkingDay = GridEntry;

export type LegendEntries = Record<string, string>;

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnChanges {
  @Input() startDate?: Date;
  @Input() endDate?: Date;
  @Input() legend: LegendEntries = {};
  @Input() daysOff?: Date[] = [];

  private static readonly LANG = navigator.language;

  private _gridChanged = new Subject<void>();
  gridChanged$ = this._gridChanged.asObservable();

  today = new Date();
  showToday = false;

  days: Date[] = [];
  months: Month[] = [];
  noWorkingDays: NoWorkingDay[] = [];
  mondays: Monday[] = [];

  @ViewChild('todayEl') todayEl: ElementRef<HTMLDivElement>;

  ngOnChanges(): void {
    if (!this.startDate || !this.endDate) {
      this.resetData();
      return;
    }

    this.setupGrid();
    this.today = new Date(); // enforces rerender of today
    this.showToday = isWithinInterval(this.today, {
      start: this.startDate,
      end: this.endDate,
    });
  }

  scrollToToday() {
    this.todayEl.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }

  private setupGrid(): void {
    this._gridChanged.next();

    if (this.startDate > this.endDate) {
      throw new Error(
        `The startDate (${this.startDate.toLocaleDateString()}) should be after the endDate (${this.endDate.toLocaleDateString()}).`,
      );
    }

    this.days = this.calcDays();
    this.months = this.calcMonths();
    this.mondays = this.calcMondays();
    this.noWorkingDays = this.calcNoWorkingDays();
  }

  private calcDays(): Date[] {
    return eachDayOfInterval({
      start: startOfMonth(this.startDate),
      end: endOfMonth(this.endDate),
    });
  }

  private calcMonths(): Month[] {
    const result = eachMonthOfInterval({
      start: first(this.days),
      end: last(this.days),
    });

    const firstMonth = result[0];

    return result.map((month) => {
      const startDay = differenceInDays(month, firstMonth) + 1;

      return {
        name: month.toLocaleDateString(TimelineComponent.LANG, {
          month: 'short',
        }),
        year: month.toLocaleDateString(TimelineComponent.LANG, {
          year: '2-digit',
        }),
        col: startDay,
      };
    });
  }

  private calcMondays(): Monday[] {
    return this.days
      .filter((day) => isMonday(day))
      .map((currMonday) => {
        const difference = this.convertDateToColumn(currMonday);

        return {
          text: currMonday.toLocaleDateString(TimelineComponent.LANG, {
            day: '2-digit',
          }),
          col: difference,
          kw: getWeek(currMonday, {
            locale: { code: TimelineComponent.LANG },
            weekStartsOn: 1,
            firstWeekContainsDate: 4,
          }).toString(),
        };
      });
  }

  private calcNoWorkingDays(): NoWorkingDay[] {
    return [...this.days.filter((day) => isWeekend(day)), ...(this.daysOff ? this.daysOff : [])].map((currDayOff) => ({
      col: this.convertDateToColumn(currDayOff),
    }));
  }

  private convertDateToColumn(date: Date): number {
    if (!this.days) return 0;

    return convertDateToColumn(startOfMonth(this.startDate), date);
  }

  private resetData() {
    this.days = [];
    this.months = [];
    this.mondays = [];
    this.noWorkingDays = [];
    this.showToday = false;
  }

  unsorted = () => 0;

  trackMondays = (index: number, monday: Monday) => monday.kw + monday.text;

  trackMonths = (index: number, month: Month) => month.name + month.year;
}
